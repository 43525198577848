var $ = require("jquery");
//var breakpoint = require('./app-ddslick.js');
var breakpoint = require('./app-breakpoints');
var _gridSize = breakpoint().match(/(\d+)/g);
var _winSize = $(window).width();
var	_gridLabel = breakpoint().replace(/\d+/g, '').replace(" ", "").replace(/\"/g, ""); 
var _margin = '';

function initMap(mapinfo) {
	if ($.isArray(mapinfo.datalat) && $.isArray(mapinfo.datalng)) {
		var myLat = mapinfo.datalat;
		var myLng = mapinfo.datalng;
		var myLatLng = { lat: myLat[0].value, lng: myLng[0].value };
	} else {
		var myLatLng = { lat: mapinfo.datalat, lng: mapinfo.datalng };
	}
	
	if (mapinfo.center) {
		var center_map = mapinfo.center;
	}else{
		var center_map = myLatLng;
	}

	var styles = [ 
	{
		"featureType": "administrative", 
			"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#2a2569"
					}
				]
	},
	{
		"featureType": "landscape",
			"elementType": "all",
				"stylers": [
					{
						"color": "#f2f2f2"
					}
				]
	},
	{
		"featureType": "poi",
			"elementType": "all",
				"stylers": [
					{
						"visibility": "off"
					}
				]
	},
	{
		"featureType": "road",
			"elementType": "all",
				"stylers": [
					{
						"saturation": -100
					},
					{
						"lightness": 45
					}
				]
	},
	{
		"featureType": "road.highway",
			"elementType": "all",
				"stylers": [
					{
						"visibility": "simplified"
					}
				]
	},
	{
		"featureType": "road.highway",
			"elementType": "geometry",
				"stylers": [
					{
						"visibility": "simplified"
					},
					{
						"color": "#dedede"
					}
				]
	},
	{
		"featureType": "road.arterial",
			"elementType": "labels.icon",
				"stylers": [
					{
						"visibility": "off"
					}
				]
	},
	{
		"featureType": "transit",
			"elementType": "all",
				"stylers": [
					{
						"visibility": "off"
					}
				]
	},
	{
		"featureType": "water",
			"elementType": "all",
				"stylers": [
					{
						"color": "#ffffff"
					},
					{
						"visibility": "simplified"
					}
				]
	}
];
	
	//console.log(center_map);
	
	var map = new google.maps.Map(document.getElementById(mapinfo.id), {
		zoom: mapinfo.zoom,
		disableDefaultUI: mapinfo.disableDefaultUI,
		draggable: mapinfo.draggable,
		draggableCursor: mapinfo.draggableCursor,
		mapTypeId: mapinfo.maptype,
		center: center_map,
		mapTypeControl: false,
		scaleControl: false,
		streetViewControl: false,
		fullscreenControl: false,
		zoomControl: false,
		scrollwheel: false,
		styles: styles
		
	});
	if (mapinfo.disableDefaultUI == false) {
		
		var image = document.location.origin + '/app/themes/wp-ecoledeconduite/dist/pin_default.png';

		var bounds = new google.maps.LatLngBounds();
		var infowindow = new google.maps.InfoWindow();  

		var marker, i;

		for (i = 0; i < myLat.length; i++) {
			image = document.location.origin + '/app/themes/wp-ecoledeconduite/dist/pin_' + (i+1) + '.png';
			marker = new google.maps.Marker({
				position: new google.maps.LatLng(myLat[i].value, myLng[i].value),
				map: map,
				icon: image
		
			});

			//extend the bounds to include each marker's position
			bounds.extend(marker.position);

			// google.maps.event.addListener(marker, 'click', (function (marker, i) {
			// 	return function () {
			// 		infowindow.setContent(locations[i][0]);
			// 		infowindow.open(map, marker);
			// 	}
			// })(marker, i));
			
			
			/*google.maps.event.addListener(marker, 'click', function () {
				console.log(marker);
				console.log('https://www.google.com/maps/dir/Current+Location/' + marker.lat + ',%20' + marker.lng);
				window.open('https://www.google.com/maps/dir/Current+Location/' + marker.lat + ',%20' + marker.lng);
			});*/
			google.maps.event.addListener(marker, 'click', (function (marker, i) {
				return function () {
					
					window.open('https://www.google.com/maps/dir/Current+Location/' + myLat[i].value + ',%20' + myLng[i].value);
				}
			})(marker, i));
		}
		//now fit the map to the newly inclusive bounds
		map.fitBounds(bounds);
	}
}

window.preparMap = function () {
	//bounds = new google.maps.LatLngBounds();
	//infowindow = new google.maps.InfoWindow();
	var datalat = "";
	var datalng = "";
	var id = "";
	var disableDefaultUI = false;
	var draggable = true;
	var draggableCursor = '';
	var zoom = 10;
	var marker = "default";
	var maptype = "roadmap";
	var center;
	$('.vy_google_map').each(function () {
		datalat = $(this).data('lat');
		datalng = $(this).data('lng');
		// console.log(datalat[0].value);
		// console.log(datalng);

		if ($(this).attr('data-zoom')) {
			zoom = parseInt($(this).data('zoom'));
		}
		if ($(this).attr('data-center-lat') && $(this).attr('data-center-lng')) {
			center = { lat: $(this).data('center-lat'), lng: $(this).data('center-lng') };
		}
		if ($(this).attr('data-marker')) {
			marker = $(this).data('marker');
		}
		if ($(this).attr('data-maptype')) {
			maptype = $(this).data('maptype');
		}
		id = $(this).attr('id');
		if ($(this).data('disableui') == true) {
			disableDefaultUI = $(this).data('disableui');
			draggable = false;
			draggableCursor = 'default';
		}

		// console.log('---vy_google_map---');
		// console.log('ID: ' + id);
		// console.log('LAT: ' + datalat);
		// console.log('LNG: ' + datalng);
		// console.log('center: ');
		// console.dir(center);
		// console.log('zoom: ' + zoom);
		// console.log('disableDefaultUI: ' + disableDefaultUI);
		// console.log('draggable: ' + draggable);
		// console.log('draggableCursor: ' + draggableCursor);
		// console.log('marker: ' + marker);
		var mapinfo = { datalat: datalat, datalng: datalng, id: id, zoom: zoom, disableDefaultUI: disableDefaultUI, draggable: draggable, draggableCursor: draggableCursor, marker: marker, center: center, maptype: maptype };
		initMap(mapinfo);
	});
}

function loadScript(callback) {
	var script = document.createElement('script');
	script.type = 'text/javascript';
	script.src = 'https://maps.googleapis.com/maps/api/js?v=3.exp' +
		'&callback=' + callback + '&key=AIzaSyDJYrHsr4OpB6oYZn5fqcglEU-h2kCX1FI';
	document.body.appendChild(script);
}
if (document.getElementsByClassName("vy_google_map").length > 0) {
	window.onload = loadScript('preparMap');
}

function fileUpload(object){
	// console.log(object);
	
	var filename = $(object).val();

	var fullPath = filename;
	if (fullPath) {
		var startIndex = (fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/'));
		filename = fullPath.substring(startIndex);
		if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
			filename = filename.substring(1);
		}
	}

	$('.vy_form_file_name').show();
	$('.vy_form_file_name_text').text(filename);
}

$(function() {
	// $(window).scroll(function() {
	// 	if(_gridLabel != "smartphone") {
		
	// 	}
	// });
	// UIkit.modal('#modal-home').show();
	console.log('ready');
	console.log($('#js_home_popup').length);
	if ($('#js_home_popup').length) {
		console.log('JS BOUTON');
		$('#js_home_popup').trigger("click");
		document.getElementById("js_home_popup").click();
	}
	$('.file-93 input').change(function () {
		//console.log('change value');
		fileUpload($(this));
	});


	var getUrlParameter = function getUrlParameter(sParam) {
		var sPageURL = decodeURIComponent(window.location.search.substring(1)),
			sURLVariables = sPageURL.split('&'),
			sParameterName,
			i;

		for (i = 0; i < sURLVariables.length; i++) {
			sParameterName = sURLVariables[i].split('=');

			if (sParameterName[0] === sParam) {
				return sParameterName[1] === undefined ? true : sParameterName[1];
			}
		}
	};

	var subject_value = getUrlParameter('subject');

	
	if (subject_value > '') {
		$('select[name="subject"]').val(subject_value);
	}
	
	// function() {
	// 	$('div.total-title').text('test');
	// }

	// $('.vy_filter_select')

	$('.vy_filter_dropdown').find('a').click(function(){
		var filter_value = $(this).text();
		$('.vy_filter_select').text(filter_value);
	});

	$('.unwrap').unwrap();
	$('.uk-form-custom').find('input[type="file"]').change(function () {
		$('.uk-form-custom').find('.uk-input').addClass('vy_form_file_added');
	});
	
	$('.wpcf7-select option[value="Sujet"]').attr('disabled', "disabled");
	
  $(window).resize(function(){
		_gridSize = breakpoint().match(/(\d+)/g);
		_winSize = $(window).width();
		_margin = (_winSize - _gridSize) / 2;

		_gridLabel = breakpoint().replace(/\d+/g, '').replace(' ','');

		if(_gridLabel == "smartphone") {
		
		}

	}).resize();
});